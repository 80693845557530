import React, { FC } from 'react'
import { ReactComponent as IconDownload } from 'iadocs/assets/images/iconDownload.svg'
import { useTranslation } from 'react-i18next'
import { useLazyDownloadFileQuery } from 'iadocs/api/document'

export const DownloadButton: FC<{
  documentId: number
  filename: string
}> = ({ documentId, filename }) => {
  const { t } = useTranslation()
  const [triggerDownload, { data: fileBlob, isLoading, error }] =
    useLazyDownloadFileQuery()

  const handleDownload = async () => {
    const result = await triggerDownload(documentId).unwrap()

    if (result) {
      const url = URL.createObjectURL(result)

      const link = document.createElement('a')
      link.href = url
      link.download = filename

      link.click()

      URL.revokeObjectURL(url)
    }
  }

  return (
    <button
      onClick={handleDownload}
      className="button-download"
      disabled={isLoading}
    >
      {isLoading
        ? t('iadocs.uploaded.button.downloadLoading')
        : t('iadocs.uploaded.button.download')}
      <IconDownload />
    </button>
  )
}
