import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as UploadButtonSmall } from 'iadocs/assets/images/uploadButtonSmall.svg'
import { ReactComponent as UploadButtonSmallDark } from 'iadocs/assets/images/uploadButtonSmallDark.svg'
import { ReactComponent as IconTrash } from 'iadocs/assets/images/iconTrash.svg'
import { ReactComponent as IconTrashDark } from 'iadocs/assets/images/iconTrashDark.svg'
import { ReactComponent as IconLeftLine } from 'iadocs/assets/images/iconLeftLine.svg'
import { ReactComponent as IconRightLine } from 'iadocs/assets/images/iconRightLine.svg'
import { DocumentHeader } from 'iadocs/components/DocumentHeader'
import { useNavigate } from 'react-router'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const UploadedDocument: FC<{
  file: File
  deleteFile: () => void
}> = ({ file, deleteFile }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isDarkTheme } = useContext(DarkThemeContext)

  const [error, setError] = useState<string | null>(null)
  const [type, setType] = useState<string | null>(null)

  const handleSetType = (newType: string | null) => {
    setError(null)
    setType(newType)
  }

  const handleExtractClick = () => {
    if (type === null || type === '') {
      setError('card.extract.error.typeDocumentMissing')
      return
    }

    navigate(ROUTE_PATHS.IADOCS_EXTRACT, { state: { file, type: type } })
  }

  const handleUnbundleClick = () => {
    navigate(ROUTE_PATHS.IADOCS_UNBUNDLE, { state: { file } })
  }

  return (
    <>
      <div className="uploaded-document">
        <div>
          {isDarkTheme ? (
            <UploadButtonSmallDark className="upload-button" />
          ) : (
            <UploadButtonSmall className="upload-button" />
          )}
        </div>
        <div className="uploaded-document-description">
          <h4>{t('iadocs.upload.file.loaded')}</h4>
          <p>{file && file.name}</p>
        </div>
        <div>
          <button onClick={deleteFile}>
            {isDarkTheme ? <IconTrashDark /> : <IconTrash />}
          </button>
        </div>

        <IconLeftLine className="line left-[40px]" />
        <IconRightLine className="line left-[380px]" />
      </div>
      <div className="flex flex-row">
        <div className="document">
          <DocumentHeader
            type="extract"
            processFile={handleExtractClick}
            setType={handleSetType}
            error={error}
          />
          {error ? <p className="error-message">{t(error)}</p> : ''}
        </div>
        <div className="document ml-[60px]">
          <DocumentHeader type="unbundle" processFile={handleUnbundleClick} />
        </div>
      </div>
    </>
  )
}
