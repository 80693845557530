import { useContext } from 'react'
import Keycloak, {
  KeycloakTokenParsed as BaseKeycloakTokenParsed,
} from 'keycloak-js'
import { KeycloakRoleMalFormedError } from 'core/packages/KeycloakProvider/KeycloakRoleMalFormedError'
import { KeycloakContext } from 'core/packages/KeycloakProvider/KeycloakContext'

export interface KeycloakTokenParsed extends BaseKeycloakTokenParsed {
  name?: string
  email?: string
}

export type KeycloakRoleDefinition = undefined | string | string[]

export class KeycloakService {
  private initialized: boolean

  private keycloakInstance: Keycloak

  public constructor(keycloak: Keycloak, initialized: boolean) {
    this.keycloakInstance = keycloak
    this.initialized = initialized
  }

  public isInitialized() {
    return this.initialized
  }

  public checkRole(role?: KeycloakRoleDefinition): boolean {
    if (!this.keycloakInstance.authenticated) {
      return false
    }

    if (role === undefined) {
      return this.keycloakInstance.authenticated
    }

    if (typeof role === 'string') {
      const splitedRole = role.split(':')

      if (splitedRole.length === 1) {
        return this.keycloakInstance.hasResourceRole(splitedRole[0])
      }
      if (splitedRole.length === 2) {
        if (splitedRole[0] === 'realm') {
          return this.keycloakInstance.hasRealmRole(splitedRole[1])
        }
        return this.keycloakInstance.hasResourceRole(
          splitedRole[1],
          splitedRole[0]
        )
      }
      throw new KeycloakRoleMalFormedError()
    } else {
      return role.map((item) => this.checkRole(item)).indexOf(false) === -1
    }
  }

  public getKc() {
    return this.keycloakInstance
  }

  public accountManagement() {
    return this.getKc().accountManagement()
  }

  public isAutenticated() {
    return this.keycloakInstance.authenticated || false
  }

  public getName() {
    if (typeof this.getTokenParsed() === 'object') {
      return this.getTokenParsed().name || ''
    }
    return ''
  }

  public getEmail() {
    if (typeof this.getTokenParsed() === 'object') {
      return this.getTokenParsed().email || ''
    }
    return ''
  }

  public getTokenParsed() {
    return this.getKc().tokenParsed as KeycloakTokenParsed
  }
}

export function UseKeycloakService() {
  const ctx = useContext(KeycloakContext)

  if (!ctx) {
    throw new Error(
      'useKeycloak hook must be used inside ReactKeyService context'
    )
  }

  if (!ctx.keycloakInstance) {
    throw new Error('authClient has not been assigned to ReactKeyService')
  }

  const { keycloakInstance, initialized } = ctx

  return new KeycloakService(keycloakInstance, initialized)
}

export function UseKeycloakCheckRole() {
  const kc = UseKeycloakService()
  return (role: KeycloakRoleDefinition) => kc.checkRole(role)
}

export default UseKeycloakService
