import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { useExtractMutation } from 'iadocs/api/document'
import { ExtractedData } from 'iadocs/components/ExtractedData'
import Loader from 'core/components/common/Loader'
import { useTranslation } from 'react-i18next'
import { useProcessFile } from 'iadocs/hooks/useProcessFile'

export const ExtractPage = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const type = location.state?.type as string | undefined

  const { convertToBase64, error, setError, apiData, setApiData, file } =
    useProcessFile()

  const [extract, { isLoading }] = useExtractMutation()

  useEffect(() => {
    let isMounted = true

    if (!file || !type || apiData) {
      setError('iadocs.upload.file.missingFile')
      return
    }

    const controller = new AbortController()

    const processFile = async () => {
      try {
        const base64String = await convertToBase64(file)

        if (isMounted) {
          const response = await extract({
            idDossier: uuidv4(),
            files: [base64String],
            type: type,
            origine: 'react-iadocs',
          }).unwrap()

          setApiData(response)
        }
      } catch (err: any) {
        if (isMounted && err?.data?.violations) {
          const violations = err.data.violations
          console.log('error', violations)
        } else {
          console.log('error', err)
          setError(err || 'An error occurred during the extraction process.')
        }
      }
    }

    processFile()

    return () => {
      controller.abort()
      isMounted = false
    }
  }, [file, type, extract, setError, setApiData])

  if (isLoading) {
    return (
      <Loader center className="h-screen flex items-center justify-center" />
    )
  }

  return (
    <>
      {apiData ? <ExtractedData data={apiData} /> : null}
      {error && <p className="error-message">{t('error')}</p>}
    </>
  )
}
