import { NavLink } from 'react-router-dom'

export const Menu = () => {
  const menuItems = [
    { name: 'iastart', logo: 'logoIaStartDark.svg', route: '/iastart' },
    { name: 'iadocs', logo: 'logoIaDocsDark.svg', route: '/iadocs' },
  ]
  return (
    <div className="side-menu">
      <nav className="flex flex-col items-start">
        {menuItems.map((item) => (
          <NavLink
            key={item.name}
            to={item.route}
            className={({ isActive }) =>
              `flex items-center p-4 w-full ${
                isActive ? 'menu-item-active' : ''
              }`
            }
          >
            <img
              src={require(`core/assets/images/logos/${item.logo}`)}
              alt={`${item.name} logo`}
              className="h-[50px]"
            />
          </NavLink>
        ))}
      </nav>
    </div>
  )
}
