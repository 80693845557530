import React, { FC } from 'react'
import { ReactComponent as IconPagination } from 'iadocs/assets/images/iconPagination.svg'

export const Pagination: FC<{
  nbDocuments: number
  currentDocument: number
  onChangePage: (page: number) => void
}> = ({ nbDocuments, currentDocument, onChangePage }) => {
  const pageLinks = []

  const handleClick = (page: number) => {
    onChangePage(page)
  }

  pageLinks.push(
    <li key="page1">
      <a
        className={currentDocument === 1 ? 'selected' : ''}
        onClick={() => handleClick(1)}
      >
        1
      </a>
    </li>
  )

  if (currentDocument > 3) {
    pageLinks.push(<IconPagination key="icon1" />)
  }

  for (
    let i = Math.max(1, currentDocument - 1);
    i <= Math.min(nbDocuments - 1, currentDocument + 1);
    i++
  ) {
    if (i !== 1 && i !== nbDocuments) {
      pageLinks.push(
        <li key={`page${i}`}>
          <a
            className={currentDocument === i ? 'selected' : ''}
            onClick={() => handleClick(i)}
          >
            {i}
          </a>
        </li>
      )
    }
  }

  if (currentDocument < nbDocuments - 2) {
    pageLinks.push(<IconPagination key="icon2" />)
  }

  if (nbDocuments !== 1) {
    pageLinks.push(
      <li key={`page${nbDocuments}`}>
        <a
          className={currentDocument === nbDocuments ? 'selected' : ''}
          onClick={() => handleClick(nbDocuments)}
        >
          {nbDocuments}
        </a>
      </li>
    )
  }

  return <ul>{pageLinks}</ul>
}
