const envConstants = {
  dev: {
    API_BASE_URL_IASTART: 'http://localhost:8087/iamanagementbff/v1',
    API_BASE_URL_IADOCS: 'http://localhost:8082/iadocs/v1',
    KEYCLOAK_URL: 'https://sso.preprod.legal2digital.net',
    KEYCLOAK_REALM: 'qual',
    KEYCLOAK_CLIENT_ID: 'react-iasuite',
  },
  qual: {
    API_BASE_URL_IASTART:
      'https://api.qual.legal2digital.net/iamanagementbff/v1',
    API_BASE_URL_IADOCS: 'https://api.qual.legal2digital.net/iadocs/v1',
    KEYCLOAK_URL: 'https://sso.preprod.legal2digital.net',
    KEYCLOAK_REALM: 'qual',
    KEYCLOAK_CLIENT_ID: 'react-iasuite',
  },
  preprod: {
    API_BASE_URL_IASTART: 'todo',
    API_BASE_URL_IADOCS: 'todo',
    KEYCLOAK_URL: 'https://sso.preprod.legal2digital.net',
    KEYCLOAK_REALM: 'preprod',
    KEYCLOAK_CLIENT_ID: 'react-iasuite',
  },
  prod: {
    API_BASE_URL_IASTART: 'https://api.legal2digital.net/iamanagementbff/v1',
    API_BASE_URL_IADOCS: 'https://api.legal2digital.net/iadocs/v1',
    KEYCLOAK_URL: 'https://sso.legal2digital.net',
    KEYCLOAK_REALM: 'prod',
    KEYCLOAK_CLIENT_ID: 'react-iasuite',
  },
}

export default envConstants
