export const DOCUMENTS_OPTIONS = [
  { label: 'Annexes Bilan', value: 'AnnexesBilan' },
  { label: 'Amplication CAC', value: 'AmpCAC' },
  { label: 'Attestation de Dépôt des Fonds', value: 'DepotFonds' },
  { label: 'Attestation de Domiciliation', value: 'AttDom' },
  { label: 'Bilan', value: 'Bilan' },
  { label: 'Cession de Parts', value: 'CessionPart' },
  { label: 'Comptes Annuels', value: 'ComptesAnnuels' },
  { label: 'Comptes de Liquidation', value: 'ComptesLiquidation' },
  { label: 'Compte de Résultats', value: 'CompteResultats' },
  { label: 'Déclaration de Non Condamnation', value: 'DNC' },
  { label: 'Déclaration de Confidentialité', value: 'DeclarationConf' },
  { label: 'Imprimé MBE', value: 'ImprimeMBE' },
  { label: 'Justificatif de Siège', value: 'JustifSiege' },
  { label: 'Kbis', value: 'Kbis' },
  { label: 'Lettre Acc CAC', value: 'LettreAccCac' },
  { label: 'Lettre Client', value: 'LettreClient' },
  { label: 'Liste des Sièges Sociaux Antérieurs', value: 'ListeSieges' },
  { label: 'Liste des Souscripteurs', value: 'ListeSouscripteur' },
  { label: "Pièce d'Identité", value: 'JDI' },
  { label: 'Pouvoir', value: 'Pouvoir' },
  { label: 'PV de Décision', value: 'PvDecision' },
  { label: 'Rapport CAC', value: 'RapportCac' },
  { label: 'Rapport de Gestion', value: 'RapportGestion' },
  { label: 'Statuts Constitutifs', value: 'StatutsConstitutifs' },
  { label: 'Statuts Mis à Jour', value: 'MajStatuts' },
]
