import React, { FC } from 'react'
import 'core/assets/styles/loader.css'

const Loader: FC<{
  center?: boolean
  pageCenter?: boolean
  className?: string
  size?: 'small' | 'normal'
}> = ({
  center = false,
  pageCenter = false,
  className = '',
  size = 'normal',
}) => {
  const sizeClass = size === 'small' ? 'loader-sm' : ''
  return (
    <div
      id="loader"
      className={`${className} ${center ? 'text-center' : ''} ${pageCenter ? ' w-full flex items-center justify-center' : ''}`}
    >
      <div className={`loader ${sizeClass}`} aria-label="loading" />
    </div>
  )
}

export default Loader
