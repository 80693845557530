import { useState } from 'react'
import { useLocation } from 'react-router'

export const useProcessFile = () => {
  const location = useLocation()

  const [error, setError] = useState<string | null>(null)
  const [apiData, setApiData] = useState<any | null>(null)

  const file = location.state?.file as File | undefined

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        const result = reader.result as string
        const base64String = result.split(',')[1]
        resolve(base64String)
      }

      reader.onerror = () => {
        reject('upload.file.readingError')
      }

      reader.readAsDataURL(file)
    })
  }

  return {
    convertToBase64,
    error,
    setError,
    apiData,
    setApiData,
    file,
  }
}
