import { defaultEnv } from './currentEnv'
import envConstants from './envConstants'

export const API_BASE_URL_IADOCS = envConstants[defaultEnv].API_BASE_URL_IADOCS
export const API_BASE_URL_IASTART =
  envConstants[defaultEnv].API_BASE_URL_IASTART
export const KEYCLOAK_URL = envConstants[defaultEnv].KEYCLOAK_URL
export const KEYCLOAK_REALM = envConstants[defaultEnv].KEYCLOAK_REALM
export const KEYCLOAK_CLIENT_ID = envConstants[defaultEnv].KEYCLOAK_CLIENT_ID
export const DEFAULT_APP_COLOR = '#48AC79'
export const DEFAULT_APP_DARK_COLOR = '#3D7AB4'
export const DEFAULT_APP_ICONE = 'iconeServiceDefaultSmall.svg'
export const DEFAULT_APP_ICONE_DARK = 'iconeServiceDefaultSmallDark.svg'
