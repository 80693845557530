import React from 'react'

export const useRenderData = () => {
  const renderTableRows = (
    obj: any,
    color: string = 'bg-white dark:bg-black'
  ) => {
    return Object.entries(obj).map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          return (
            <React.Fragment key={key}>
              <tr>
                <td className="table-key-header" colSpan={2}>
                  {key}
                </td>
              </tr>
              {value.map((item, index) =>
                typeof item === 'object' ? (
                  <React.Fragment key={`${key}-${index}`}>
                    {renderTableRows(
                      item,
                      index % 2 === 0 ? 'bg-white-150' : 'bg-gray-1075'
                    )}
                  </React.Fragment>
                ) : (
                  <tr key={`${key}-${index}`}>
                    <td colSpan={2} className="table-value">
                      {item}
                    </td>
                  </tr>
                )
              )}
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment key={key}>
              <tr>
                <td className="table-key" colSpan={2}>
                  {key}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="table-value">
                  {renderTableRows(value)}
                </td>
              </tr>
            </React.Fragment>
          )
        }
      } else {
        return (
          <tr key={key} className={color}>
            <td className="table-key">{key}</td>
            <td className="table-value">{value ? value.toString() : ''}</td>
          </tr>
        )
      }
    })
  }

  return {
    renderTableRows,
  }
}
