import React, { FC, useContext, useState } from 'react'
import { ReactComponent as IconUnbundle } from 'iadocs/assets/images/iconUnbundle.svg'
import { ReactComponent as IconUnbundleDark } from 'iadocs/assets/images/iconUnbundleDark.svg'
import { Trans, useTranslation } from 'react-i18next'
import { useRenderData } from 'iadocs/hooks/useRenderData'
import { Pagination } from 'iadocs/components/Pagination'
import { DownloadButton } from 'iadocs/components/DownloadButton'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const UnbundledData: FC<{ data: any }> = ({ data }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [selectedDocument, setSelectedDocument] = useState<number>(1)

  const { renderTableRows } = useRenderData()

  const nbDocuments = data.documents.length

  const handleChangePage = (page: number) => {
    setSelectedDocument(page)
  }

  return (
    <div className="flex flex-col items-center">
      {nbDocuments <= 1 ? (
        <div className="api-data-header">
          <div>
            {isDarkTheme ? (
              <IconUnbundleDark className="upload-button text-purple-1100" />
            ) : (
              <IconUnbundle className="upload-button text-purple-1100" />
            )}
          </div>
          <div className="uploaded-document-description">
            <h3 className="uploaded-document-description-h3">
              {t('iadocs.uploaded.unbundle.title')}
            </h3>
            <p className="uploaded-document-description-p">
              {t('iadocs.uploaded.unbundle.description')}
            </p>
          </div>
        </div>
      ) : (
        <div className="api-data-header-unbundle">
          <div className="api-data-header-light">
            <div>
              <IconUnbundle className="upload-button text-purple-1100" />
            </div>
            <div className="uploaded-document-description">
              <p>
                <Trans
                  i18nKey="iadocs.uploaded.unbundle.descriptionMultiple"
                  values={{ nb: nbDocuments }}
                />
              </p>
            </div>
          </div>
          <div className="api-data-header-pagination">
            <div className="pagination">
              <Pagination
                nbDocuments={nbDocuments}
                currentDocument={selectedDocument}
                onChangePage={handleChangePage}
              />
            </div>
            <p>{t('iadocs.uploaded.unbundle.pagination')}</p>
          </div>
        </div>
      )}

      <div className="api-data-display-unbundle">
        {data.documents.length === 0 ? (
          <table>
            <thead>
              <tr>
                <th colSpan={2}>{t('iadocs.uploaded.unbundle.noData')}</th>
              </tr>
            </thead>
          </table>
        ) : (
          data.documents.map((document: any, index: number) => {
            if (index === selectedDocument - 1) {
              return (
                <table key={index}>
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <div className="table-header">
                          <span>{document.type}</span>
                          <DownloadButton
                            documentId={document.idDocument}
                            filename={`document_${document.type}.pdf`}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {document.donneesExtraites ? (
                      renderTableRows(document.donneesExtraites)
                    ) : (
                      <tr>
                        <td className="table-key-header" colSpan={2}>
                          {t('iadocs.uploaded.unbundle.noData')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )
            }
            return null
          })
        )}
      </div>
    </div>
  )
}
