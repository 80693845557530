import React from 'react'
import { LayoutSubHeader } from 'core/components/layout/LayoutSubHeader'
import { Menu } from 'core/components/layout/Menu'
import { Outlet } from 'react-router'

export const LayoutApp = () => {
  return (
    <div className="w-full h-full">
      <LayoutSubHeader />
      <div className="flex flex-row h-[calc(100vh-180px)]">
        <Menu />
        <div className="flex-grow overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
