import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { CurrentHeaderContext } from 'core/contexts/CurrentHeaderContext'

export const LayoutSubHeader = () => {
  const { header } = useContext(CurrentHeaderContext)
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { t } = useTranslation()

  const svgPath = require(`core/assets/images/logos/${header.svgPath}`)
  const svgPathDark = require(`core/assets/images/logos/${header.svgPathDark}`)

  return (
    <div className="sub-header">
      <img
        src={isDarkTheme ? svgPathDark : svgPath}
        alt="Icon"
        className="h-[50px]"
      />
      <div className="font-light">{t(header.accroche)}</div>
    </div>
  )
}
