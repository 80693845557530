import React, { FC, useContext } from 'react'
import { ReactComponent as IconExtract } from 'iadocs/assets/images/iconExtract.svg'
import { ReactComponent as IconExtractDark } from 'iadocs/assets/images/iconExtractDark.svg'
import { useTranslation } from 'react-i18next'
import { useRenderData } from 'iadocs/hooks/useRenderData'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const ExtractedData: FC<{ data: any }> = ({ data }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  const { renderTableRows } = useRenderData()

  return (
    <div className="flex flex-col items-center">
      <div className="api-data-header">
        <div>
          {isDarkTheme ? (
            <IconExtractDark className="upload-button" />
          ) : (
            <IconExtract className="upload-button" />
          )}
        </div>
        <div className="uploaded-document-description">
          <h3>{t('iadocs.uploaded.extract.title')}</h3>
          <p>{t('iadocs.uploaded.extract.description')}</p>
        </div>
      </div>
      <div className="api-data-display">
        <table>
          <thead>
            <tr>
              <th colSpan={2}>{data.type}</th>
            </tr>
          </thead>
          <tbody>{renderTableRows(data.donneesExtraites)}</tbody>
        </table>
      </div>
    </div>
  )
}
