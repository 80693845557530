import React, { ReactNode } from 'react'
import { DarkModeProvider } from 'core/contexts/DarkThemeContext'
import { LayoutHeader } from 'core/components/layout/LayoutHeader'
import { CurrentHeaderProvider } from 'core/contexts/CurrentHeaderContext'

interface LayoutWrapperProps {
  children: ReactNode
  layoutClassName?: string
}

export const LayoutWrapper: React.FC<LayoutWrapperProps> = ({
  children,
  layoutClassName = 'layout-container',
}) => {
  return (
    <DarkModeProvider>
      <CurrentHeaderProvider>
        <div className={layoutClassName}>
          <LayoutHeader />
          {children}
        </div>
      </CurrentHeaderProvider>
    </DarkModeProvider>
  )
}
