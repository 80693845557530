import React, { FC, useContext } from 'react'
import { ReactComponent as UploadButton } from 'iadocs/assets/images/uploadButton.svg'
import { ReactComponent as UploadButtonDark } from 'iadocs/assets/images/uploadButtonDark.svg'
import { Trans, useTranslation } from 'react-i18next'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const DragZone: FC<{
  className?: string
}> = ({ className }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div className={className}>
      {isDarkTheme ? (
        <UploadButtonDark className="upload-button mt-[15px]" />
      ) : (
        <UploadButton className="upload-button mt-[15px]" />
      )}
      <h3 className="upload-title">{t('iadocs.upload.title')}</h3>
      <p className="upload-description">
        <Trans i18nKey="iadocs.upload.description" />
      </p>
    </div>
  )
}
