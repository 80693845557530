import React, { useEffect } from 'react'
import { useUnbundleMutation } from 'iadocs/api/document'
import Loader from 'core/components/common/Loader'
import { useProcessFile } from 'iadocs/hooks/useProcessFile'
import { useTranslation } from 'react-i18next'
import { UnbundledData } from 'iadocs/components/UnbundledData'
import { v4 as uuidv4 } from 'uuid'

export const UnbundlePage = () => {
  const { t } = useTranslation()
  const { convertToBase64, error, setError, apiData, setApiData, file } =
    useProcessFile()

  const [unbundle, { isLoading }] = useUnbundleMutation()

  useEffect(() => {
    let isMounted = true

    if (!file || apiData) {
      setError('iadocs.upload.file.missingFile')
      return
    }

    const controller = new AbortController()

    const processFile = async () => {
      try {
        const base64String = await convertToBase64(file)

        if (isMounted) {
          const response = await unbundle({
            idDossier: uuidv4(),
            file: base64String,
            origine: 'react-iadocs',
            extraction: true,
          }).unwrap()

          setApiData(response)
        }
      } catch (err: any) {
        if (isMounted && err?.data?.violations) {
          const violations = err.data.violations
          console.log('error', violations)
        } else {
          setError(err || 'An error occurred during the extraction process.')
        }
      }
    }

    processFile()

    return () => {
      controller.abort()
      isMounted = false
    }
  }, [file, unbundle, setError, setApiData])

  if (isLoading) {
    return (
      <Loader center className="h-screen flex items-center justify-center" />
    )
  }

  return (
    <>
      {apiData ? <UnbundledData data={apiData} /> : null}
      {error && <p className="error-message">{t(error)}</p>}
    </>
  )
}
